// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

require("./planning")

import 'bootstrap'
require("bootstrap/dist/css/bootstrap.css")

import 'material-design-lite'
require("material-design-lite/dist/material.blue-red.min.css")

// for bootstrap 4 tooltips
import 'tether'
require("tether/dist/css/tether.css")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import flatpickr from "flatpickr"
require("flatpickr/dist/flatpickr.css")

document.addEventListener('DOMContentLoaded', function() {
  flatpickr("[data-behavior='flatpickr-arrival']", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d"
  }),
  flatpickr("[data-behavior='flatpickr-departure']", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d"
  })
})
