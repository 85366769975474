import { Calendar } from '@fullcalendar/core'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import listPlugin from '@fullcalendar/list';
// import listPlugin from '@fullcalendar/list'
import elLocale from '@fullcalendar/core/locales/el'

// Fullcalendar css
// require("@fullcalendar/core/main.css")
// require("@fullcalendar/timeline/main.css")
// require("@fullcalendar/resource-timeline/main.css")
// require("@fullcalendar/list/main.css")

document.addEventListener('DOMContentLoaded', function() {

  var calendarEl = document.getElementById('calendar')

  var calendar = new Calendar(calendarEl, {

    schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
    plugins: [ resourceTimelinePlugin, listPlugin ],
    firstDay: 1,
    locale: elLocale,
    initialView: 'resourceTimelineMonth',
    aspectRatio: 1.5,
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'listDay,resourceTimelineMonth'
    },
    views: {
      listDay: {
        // buttonText: 'Day'
      },
      resourceTimelineMonth: {
        // buttonText: 'Month',
        navLinks: true
      }
    },
    height: 'auto',
    contentHeight: 'auto',
    editable: true,
    eventOverlap: false,
    resourceAreaWidth: '10%',
    resourceAreaHeaderContent: 'Rooms',
    resources: '/rooms.json',
    events: '/bookings.json',
  })

  // desplay fullcalendar only index page
  if ((window.location.pathname == '/') || (window.location.pathname == '/planning')) {
    calendar.render()
  }

})
